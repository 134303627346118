import clsx from 'clsx'
import { type ReactNode } from 'react'
import ImageOptimize from '../ImageOptimize'

interface ButtonProps {
  children: ReactNode
  primary?: boolean
  secondary?: boolean
  className?: string
  id?: string
  onClick?: () => void
  disabled?: boolean
  type?: 'submit' | 'button' | 'reset' | undefined
  iconUrl?: string | null | undefined
}

export const ButtonForm = ({
  children,
  primary = false,
  secondary = false,
  disabled = false,
  className = '',
  id = '',
  onClick,
  type = 'button',
  iconUrl,
}: ButtonProps) => {
  const primaryClassNames = primary
    ? `label-button py-3 px-4 disabled:bg-gray-medium disabled:cursor-not-allowed`
    : ''

  const secondaryClassNames = secondary
    ? `label-button border py-3 px-4  ${disabled && ' cursor-not-allowed'}`
    : ''

  const buttonClassNames = clsx(
    primaryClassNames,
    secondaryClassNames,
    className
  )

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={buttonClassNames}
      type={type}
      id={id}
    >
      {iconUrl && (
        <span className='inline-block	h-3.5 pr-2'>
          <ImageOptimize
            alt='button icon'
            src={iconUrl || ''}
            width={18}
            height={18}
            quality={100}
          />
        </span>
      )}
      {children}
    </button>
  )
}
